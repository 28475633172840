const routeConstants = {
  // Pages
  PAGE_ADMIN: '/admin',
  PAGE_CONTENT: '/content',
  PAGE_COURSES: '/courses',
  PAGE_COURSES_UPLOAD: '/courses/upload',
  PAGE_COMMUNITIES: '/communities',
  PAGE_COMMUNITIES_UPLOAD: '/communities/upload',
  PAGE_DASHBOARD: '/dashboard',
  PAGE_DASHBOARD_SCHEDULE: '/dashboard/schedules',
  PAGE_EVENTS: '/events',
  PAGE_EVENTS_UPLOAD: '/events/upload',
  PAGE_RESTRICTED: '/restricted',
  PAGE_UPLOAD: '/upload',
  PAGE_PROFILE: '/profile',
  PAGE_PROFILE_CREATE: '/profile/create',
  PAGE_PROFILE_EDIT: '/profile/edit',
  PAGE_DOCUMENTS: '/documents',
  PAGE_TRACKS: '/tracks',
  PAGE_CHANNELS: '/channels',
  PAGE_LOGIN: '/login',
  PAGE_SIGNUP: '/signup',
  PAGE_ONBOARDING: '/onboarding',
  PAGE_ONBOARDING_VALUES: '/onboarding/values',
  PAGE_REVIEW: '/review',
  PAGE_REVIEW_COURSES: '/review/courses',
  PAGE_REVIEW_COMMUNITIES: '/review/communities',
  PAGE_REVIEW_TRACK: '/review/track',
  PAGE_REVIEW_SERIES: '/review/series',
  PAGE_SERIES: '/series',
  PAGE_TYPES: '/types',
  PAGE_COACHES: '/coaches',
  PAGE_COACHING: '/coaching',
  PAGE_TEMPLATES: '/templates',
  PAGE_SCHEDULE: '/schedule',
  PAGE_SERVICES: '/services',
  PAGE_SERVICES_UPLOAD: '/services/upload',
  PAGE_UPCOMING_SERVICES: '/services/upcoming',
  PAGE_SCHEDULER_AUTHORIZE: '/aura-scheduler/authorize',
  PAGE_SERVICES_SET_SCHEDULE: '/services/schedule',
  PAGE_REFLECTIONS: '/reflections',
  PAGE_PAYMENT: '/payment',
  PAGE_LIVE: '/live',
  PAGE_LIVE_UPLOAD: '/live/upload',
  PAGE_INSTAGRAM_LOGIN: '/live/instagram/login',
  PAGE_REVIEW_COACH: '/review/coach',
  PAGE_GROUP_CLASSES: '/groups',
  PAGE_VIDEO_CALL: '/videocall',
  PAGE_UPLOAD_PROGRAM: '/upload-program',
  PAGE_UPLOAD_PODCAST: '/upload-podcast',

  EDIT: 'edit',
  PAGE_NAMES: {
    '/admin': 'Admin',
    '/aura-scheduler/privacy-policy': 'Aura Scheduler - Privacy Policy',
    '/aura-scheduler/terms-of-service': '`Aura Scheduler - Terms of Service',
    '/aura-scheduler/contact-us': '`Aura Scheduler - Contact Us',
    '/aura-scheduler/guide': '`Aura Scheduler - Guide',
    '/channels': 'Channels Dashboard',
    '/channels/[channelId]': 'Channel',
    '/courses': 'Courses',
    '/courses/upload': 'Upload Course',
    '/courses/[courseId]/edit': 'Edit Course',
    '/dashboard': 'Dashboard',
    '/dashboard/schedules': 'Schedules',
    '/events': 'Events',
    '/events/upload': 'Upload Event',
    '/events/[eventId]/edit': 'Edit Event',
    '/login': 'Login',
    '/onboarding': 'Onboarding Welcome',
    '/onboarding/values': 'Onboarding Value Props',
    '/profile': 'Profile',
    '/profile/create': 'Create Profile',
    '/review': 'Review Dashboard',
    '/review/track/[trackId]': 'Track Review',
    '/review/courses/[courseId]': 'Course Review',
    '/review/communities/[communityId]': 'Community Review',
    '/signup': 'Sign Up',
    '/tracks': 'Track',
    '/tracks/[trackId]': 'Track',
    '/upload': 'Upload',
    '/types/[type]': 'Content Type Tracks',
    '/coaches': 'Coaches List Dashboard',
    '/coaches/[coachId]': 'Coach Tracks List',
    '/coaching': 'Coaching Dashboard',
    '/templates': 'Templates',
    '/template/[templateId]': 'Template Details',
    '/schedule': 'Schedule',
    '/live': 'Live',
    '/live/upload': 'Add Live Event',
    '/live/[liveId]/edit': 'Edit Live Event',
    '/live/instagram/login': 'Aura Live Instagram Login',
    '/services': 'Services',
    '/services/upcoming': 'Upcoming Services',
    '/services/upload': 'Create Services',
    '/services/schedule': 'Service Schedule',
    '/reflections': 'User Reflections',
    '/content': 'Content Landing',
    '/payment': 'Profile Payment',
    '/review/coach': 'Coach Review',
    '/groups': 'Group Classes',
    '/videocall[appointmentId]': 'Video Call',
    '/upload-program': 'Upload Program',
    '/aura-scheduler/authorize': 'Zoom Authorize',
  },
};

module.exports = routeConstants;
